import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import PageHeader from 'js/vendor/reports/components/PageHeader';
import InfoBanner from 'js/vendor/reports/components/InfoBanner';
import hash from 'object-hash';
import style from './style.css';
import { round } from 'lodash';
import Loader from 'cccisd-loader';

const TemplateWrapper = props => {
    const {
        settings,
        summary,
        showBanner,
        bannerText,
        widgetsConfig,
        widgetComponents,
        alwaysShownWidgets,
        shouldShowWidget,
        templateViews,
        showWhen,
        showError,
        errorText,
    } = props;
    const filtersChanged = hash(props.initialFilterValues) !== hash(props.filters);

    const getAllWidgets = () => {
        // get widgets that are always shown
        const alwaysShownViews = templateViews.filter(v => alwaysShownWidgets.includes(v.handle));
        const shownViews = [...alwaysShownViews, ...templateViews.filter(shouldShowWidget)];
        let w = [];
        for (var view of shownViews) {
            for (var section of view.sections) {
                if (!section.renderIf || section.renderIf(props.filters)) {
                    if (section.tables) {
                        for (var table of section.tables) {
                            if (!table.collapsed) {
                                w.push(table.name + 'Table');
                            }
                        }
                    }
                    if (section.charts) {
                        for (var chart of section.charts) {
                            w.push(chart.name + 'Chart');
                        }
                    }
                    if (section.component) {
                        w.push(section.name + 'Section');
                    }
                }
            }
        }
        return w;
    };

    if ((filtersChanged || !settings.filterFields.includes('filterHack')) && !showError) {
        if (showWhen) {
            const allWidgets = getAllWidgets();
            const percentLoaded = round((props.widgetsLoaded.length / allWidgets.length) * 100);
            const notLoaded = allWidgets.filter(w => !props.widgetsLoaded.includes(w));
            console.log({ notLoaded });
            return (
                <Report
                    disableDownload={!(percentLoaded >= 100)}
                    disableDownloadMessage={`Report Loading... ${percentLoaded}%`}
                    downloadFilename={`${settings.title}`}
                >
                    <Page>
                        <PageHeader
                            title={settings.title}
                            subtitle={settings.subtitle}
                            summary={summary.filter(s => s.showIf)}
                        />

                        {showBanner && <InfoBanner text={bannerText} />}
                        {widgetsConfig.map(w => {
                            const widgetComponent = React.cloneElement(widgetComponents[w.handle], {
                                groupLevel: props.settings.groupLevel,
                                key: w.handle,
                            });
                            if (alwaysShownWidgets.includes(w.handle) || shouldShowWidget(w)) {
                                return <div key={w.handle}>{widgetComponent}</div>;
                            }
                            return <div key={w.handle} />;
                        })}
                    </Page>
                </Report>
            );
        }
        return <Loader loading type="inline" />;
    }
    const beforeFiltersAppliedText =
        'Please make a selection in the report filters above. Then click the Apply Filters button to generate a report.';
    return <div className={style.noReport}>{showError ? errorText : beforeFiltersAppliedText}</div>;
};

TemplateWrapper.propTypes = {
    settings: PropTypes.object,
    filters: PropTypes.object,
    summary: PropTypes.array,
    showBanner: PropTypes.bool,
    bannerText: PropTypes.string,
    initialFilterValues: PropTypes.object,
    widgetsConfig: PropTypes.array,
    widgetComponents: PropTypes.object,
    templateViews: PropTypes.array,
    alwaysShownWidgets: PropTypes.array,
    shouldShowWidget: PropTypes.func,
    showWhen: PropTypes.bool,
    showError: PropTypes.bool,
    errorText: PropTypes.string,
    // redux
    widgetsLoaded: PropTypes.array,
};

const mapStateToProps = state => ({
    widgetsLoaded: state.app.report.widgetsLoaded,
});

export default connect(mapStateToProps, {})(TemplateWrapper);
