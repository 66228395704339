import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import style from './style.css';
import notification from 'cccisd-notification';
import { setDateTo, setDateFrom } from 'js/reducers/report.js';
import { connect } from 'react-redux';
import moment from 'moment';

const DateSelector = props => {
    const [date, setDate] = useState(props.field.value);
    const Input = ({ value, onClick }) => (
        <button type="button" className="btn btn-default" onClick={onClick}>
            {value}
        </button>
    );

    const handleChange = d => {
        const now = new Date();
        if (d > now) {
            // if date is in the future
            notification({ message: 'Cannot choose a future date.', type: 'danger' });
        } else {
            d = moment(d).format('YYYY-MM-DD HH:mm:ss');
            setDate(d);
            props.form.setFieldValue(props.field.name, applyTimezoneOffset(d));
            if (props.dateFrom) {
                setDateFrom(d);
            } else {
                setDateTo(d);
            }
        }
    };

    const applyTimezoneOffset = d => {
        // Our server uses UTC time so we need to offset the start/end date entered by client
        return moment(d)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
    };

    return (
        <div className={style.flexIt}>
            <div>
                <DatePicker
                    customInput={<Input />}
                    selected={moment(date).toDate()}
                    onChange={d => handleChange(d)}
                    showYearDropdown
                />
            </div>
        </div>
    );
};

DateSelector.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    dateTo: PropTypes.bool,
    dateFrom: PropTypes.bool,
    // redux
    setDateFrom: PropTypes.func,
    setDateTo: PropTypes.func,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
    setDateFrom,
    setDateTo,
})(DateSelector);
