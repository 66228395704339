import siteANDDischargedQuery from './graphql/siteANDDischarged.graphql';
import agencyANDDischargedQuery from './graphql/agencyANDDischarged.graphql';
import orgANDDischargedQuery from './graphql/orgANDDischarged.graphql';
import collabANDDischargedQuery from './graphql/collabANDDischarged.graphql';

import siteALLQuery from './graphql/siteAll.graphql';
import agencyALLQuery from './graphql/agencyAll.graphql';
import orgALLQuery from './graphql/orgAll.graphql';
import collabALLQuery from './graphql/collabAll.graphql';

import siteANDDischargedMeansQuery from './graphql/siteANDDischargedMeans.graphql';
import agencyANDDischargedMeansQuery from './graphql/agencyANDDischargedMeans.graphql';
import orgANDDischargedMeansQuery from './graphql/orgANDDischargedMeans.graphql';
import collabANDDischargedMeansQuery from './graphql/collabANDDischargedMeans.graphql';

import siteAllMeansQuery from './graphql/siteAllMeans.graphql';
import agencyAllMeansQuery from './graphql/agencyAllMeans.graphql';
import orgAllMeansQuery from './graphql/orgAllMeans.graphql';
import collabAllMeansQuery from './graphql/collabAllMeans.graphql';

export const queryMap = {
    site: {
        ANDDischarged: siteANDDischargedQuery,
        All: siteALLQuery,
        ANDDischargedMeans: siteANDDischargedMeansQuery,
        AllMeans: siteAllMeansQuery,
    },
    agency: {
        ANDDischarged: agencyANDDischargedQuery,
        All: agencyALLQuery,
        ANDDischargedMeans: agencyANDDischargedMeansQuery,
        AllMeans: agencyAllMeansQuery,
    },
    organization: {
        ANDDischarged: orgANDDischargedQuery,
        All: orgALLQuery,
        ANDDischargedMeans: orgANDDischargedMeansQuery,
        AllMeans: orgAllMeansQuery,
    },
    collaborator: {
        ANDDischarged: collabANDDischargedQuery,
        All: collabALLQuery,
        ANDDischargedMeans: collabANDDischargedMeansQuery,
        AllMeans: collabAllMeansQuery,
    },
};
