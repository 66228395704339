import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import style from './style.css';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import IconSelect from 'cccisd-icons/stack-check';
import IconCheck from 'cccisd-icons/checkbox-checked2';
import IconUncheck from 'cccisd-icons/checkbox-unchecked2';
import IconHalfcheck from 'cccisd-icons/checkbox-partial2';
import IconExpandClose from 'cccisd-icons/arrow-right';
import IconExpandOpen from 'cccisd-icons/arrow-down';
import IconParentClose from 'cccisd-icons/folder2';
import IconParrentOpen from 'cccisd-icons/folder-open';
import IconLeaf from 'cccisd-icons/earth2';
import { connect } from 'react-redux';
import { setRespondents } from 'js/reducers/report.js';
import Button from 'cccisd-click-button';
import classnames from 'classnames';

export const respondents = [
    {
        value: '2',
        label: 'Care Coordinator',
    },
    {
        value: '3',
        label: 'Caregiver',
    },
    {
        value: '5',
        label: 'Youth',
    },
    {
        value: '4',
        label: 'Team Member',
    },
];

const RespondentSelector = props => {
    const { limit } = props;
    const res = limit ? respondents.filter(r => limit.includes(r.value)) : respondents;

    const modal = useRef(null);
    const [checked, setChecked] = useState(res.map(r => r.value));
    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        setSelection();
    }, []);

    const getLabel = () => `Respondent Types (${checked.length})`;
    const closeModal = () => {
        modal.current.close();
    };

    const setSelection = () => {
        let selection = checked;
        if (selection.length === 0) {
            selection = [null];
        }
        props.form.setFieldValue('respondentSelectorField', selection);
        props.setRespondents(selection);
    };

    const uncheckAll = () => {
        setChecked([]);
    };

    const checkAll = () => {
        setChecked(res.map(t => t.value));
    };

    return (
        <div className={style.flex}>
            <Modal
                ref={modal}
                size="large"
                trigger={
                    <button type="button" className={classnames('btn', 'btn-default')}>
                        <IconSelect spaceRight />
                        {getLabel()}
                    </button>
                }
                title={getLabel()}
                beforeClose={setSelection}
            >
                <div className={style.flex}>
                    <CheckboxTree
                        nodes={res}
                        checked={checked}
                        expanded={expanded}
                        onCheck={c => setChecked(c)}
                        onExpand={e => setExpanded(e)}
                        icons={{
                            check: <IconCheck />,
                            uncheck: <IconUncheck />,
                            halfCheck: <IconHalfcheck />,
                            expandClose: <IconExpandClose />,
                            expandOpen: <IconExpandOpen />,
                            expandAll: <IconExpandOpen />,
                            collapseAll: <IconExpandClose />,
                            parentClose: <IconParentClose />,
                            parentOpen: <IconParrentOpen />,
                            leaf: <IconLeaf />,
                        }}
                    />
                    <div>
                        <div className={style.flex}>
                            <Button
                                className={classnames('btn', 'btn-default', style.button)}
                                title="Select All"
                                onClick={checkAll}
                            />
                            <Button
                                className={classnames('btn', 'btn-default', style.button)}
                                title="Deselect All"
                                onClick={uncheckAll}
                            />
                        </div>
                    </div>
                </div>
                <div className={style.modalFooter}>
                    <Button title="Done" className="btn btn-primary" onClick={closeModal} />
                </div>
            </Modal>
        </div>
    );
};

RespondentSelector.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    limit: PropTypes.array,
    // from redux
    setRespondents: PropTypes.func,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
    setRespondents,
})(RespondentSelector);
