import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import style from './style.css';
import notification from 'cccisd-notification';

const now = new Date();
const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const MonthSelector15th = props => {
    const [date, setDate] = useState(props.field.value);
    const Input = ({ value, onClick }) => (
        <button type="button" className="btn btn-default" onClick={onClick}>
            {value}
        </button>
    );

    const getDate15th = d => {
        d.setDate(15);
        return d;
    };

    const handleChange = d => {
        const d15 = getDate15th(d);
        // if date is in the future
        if (d15 > now) {
            // if trying to choose current month/year
            if (now.getMonth() === d15.getMonth() && d15.getYear() === now.getYear()) {
                notification({
                    message: `Cannot choose ${monthNames[now.getMonth()]} until ${
                        monthNames[now.getMonth()]
                    } 15th.`,
                    type: 'danger',
                });
            } else {
                notification({ message: 'Cannot choose a future month.', type: 'danger' });
            }
        } else {
            props.form.setFieldValue(props.field.name, d15);
            setDate(d15);
        }
    };

    return (
        <div className={style.flexIt}>
            <div>
                <DatePicker
                    customInput={<Input />}
                    selected={date}
                    onChange={d => handleChange(d)}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                />
            </div>
        </div>
    );
};

MonthSelector15th.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    startDate: PropTypes.bool,
    endDate: PropTypes.bool,
};

export default MonthSelector15th;
