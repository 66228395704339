import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import IconDown from 'cccisd-icons/arrow-down5';
import IconLeft from 'cccisd-icons/arrow-left5';
import classnames from 'classnames';

export default class Collapsable extends React.Component {
    static propTypes = {
        view: PropTypes.bool, // for styles
        collapsed: PropTypes.bool,
        title: PropTypes.string,
        content: PropTypes.element,
        forceUpdate: PropTypes.bool,
        undoForce: PropTypes.func,
    };

    state = {
        collapsed: this.props.collapsed,
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.collapsed !== this.props.collapsed) {
            this.setState({ collapsed: this.props.collapsed });
        }
        if (this.props.forceUpdate) {
            this.setState({ collapsed: this.props.collapsed });
            this.props.undoForce();
        }
    };

    toggle = () => {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed,
        }));
    };

    render() {
        if (this.props.view) {
            return (
                <div className={style.container}>
                    <div className={style.header} onClick={this.toggle}>
                        <div>{this.props.title}</div>
                        <div>{this.state.collapsed ? <IconLeft /> : <IconDown />}</div>
                    </div>
                    <div
                        className={style.body}
                        style={this.state.collapsed ? { display: 'none' } : {}}
                    >
                        {this.props.content}
                    </div>
                </div>
            );
        }
        return (
            <div className={style.container}>
                <div
                    className={classnames(
                        style.altHeader,
                        this.state.collapsed && style.marginBottom
                    )}
                    onClick={this.toggle}
                >
                    <div>
                        {this.props.title} {this.state.collapsed && '(Click to Show)'}
                    </div>
                    <div>{this.state.collapsed ? <IconLeft /> : <IconDown />}</div>
                </div>
                <div
                    className={style.altBody}
                    style={this.state.collapsed ? { display: 'none' } : {}}
                >
                    {this.props.content}
                </div>
            </div>
        );
    }
}
