import React from 'react';
import logoImage from 'images/favicon_purple.png';
import style from './style.css';
import classnames from 'classnames';
import moment from 'moment';

var dt = moment().format('MM/DD/YYYY');

const Header = props => (
    <div>
        <div className={style.header}>
            <div className={style.title}>
                <h3>{props.title}</h3>
                <h4>{props.subtitle}</h4>
            </div>
            <div className={style.logo}>
                <img alt="" height="50px" style={{ visibility: 'hidden' }} src={logoImage} />
            </div>
        </div>
        <hr className={style.reportRule} />
        <div className={style.summaryBlock}>
            <div className={classnames(style.summary, style.columns)}>
                {props.summary.map(s => (
                    <div key={s.title}>
                        {s.title}: <span className={style.value}>{s.value}</span>
                    </div>
                ))}
            </div>
            <div>
                <div className={style.summary}>
                    Report Generated: <span className={style.value}>{dt}</span>
                </div>
            </div>
        </div>
    </div>
);

export default Header;
