import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import classnames from 'classnames';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import IconSelect from 'cccisd-icons/stack-check';
import IconCheck from 'cccisd-icons/checkbox-checked2';
import IconUncheck from 'cccisd-icons/checkbox-unchecked2';
import IconHalfcheck from 'cccisd-icons/checkbox-partial2';
import IconExpandClose from 'cccisd-icons/arrow-right';
import IconExpandOpen from 'cccisd-icons/arrow-down';
import IconParentClose from 'cccisd-icons/folder2';
import IconParrentOpen from 'cccisd-icons/folder-open';
import IconLeaf from 'cccisd-icons/earth2';
import { connect } from 'react-redux';
import { getSiteTree } from 'js/selectors/sites.js';
import { loadGroups } from 'js/reducers/sites.js';
import { setSites } from 'js/reducers/report.js';

import style from './style.css';
import Button from 'cccisd-click-button';

const previousSitesKey = `${window.cccisd.fortress.user.acting.respondent_hash}-sites`;

const SiteSelector = props => {
    if (props.groups.length === 0 && !props.loaded) {
        props.loadGroups();
    }
    const modal = useRef(null);
    const [checked, setChecked] = useState(props.field.value);

    useEffect(() => {
        props.setSites(checked);
    }, []);

    const [expanded, setExpanded] = useState([]);
    const [nodes, setNodes] = useState(false);
    const [leaves, setLeaves] = useState(false);

    const setSelection = () => {
        const checkedSiteIds = checked.map(s => parseInt(s, 10));
        props.form.setFieldValue('siteSelectorField', checkedSiteIds);
        localStorage.setItem(previousSitesKey, checked);
        props.setSites(checked);
    };

    const sortNodesLeaves = () => {
        let n = [];
        let l = [];
        for (var i = 0; i < props.groups.length; i++) {
            const group = props.groups[i];
            if (group.group.groupType === 'site') {
                l.push(group.group.groupId);
            } else {
                n.push(group.group.groupId);
            }
        }
        setNodes(n);
        setLeaves(l);
    };

    if (props.groups.length > 0 && !nodes) {
        sortNodesLeaves();
    }

    const getLabel = () => `Select Sites (${checked.length})`;

    const checkAll = () => {
        setChecked(leaves);
    };

    const uncheckAll = () => {
        setChecked([]);
    };

    const expandAll = () => {
        setExpanded(nodes);
    };

    const collapseAll = () => {
        setExpanded([]);
    };

    const closeModal = () => {
        modal.current.close();
    };

    return (
        <div className={style.flex}>
            <Modal
                ref={modal}
                size="large"
                trigger={
                    <button type="button" className={classnames('btn', 'btn-default')}>
                        <IconSelect spaceRight />
                        {getLabel()}
                    </button>
                }
                title={getLabel()}
                beforeClose={setSelection}
            >
                <div className={style.flex}>
                    <CheckboxTree
                        nodes={props.siteTree || {}}
                        checked={checked}
                        expanded={expanded}
                        onCheck={c => setChecked(c)}
                        onExpand={e => setExpanded(e)}
                        icons={{
                            check: <IconCheck />,
                            uncheck: <IconUncheck />,
                            halfCheck: <IconHalfcheck />,
                            expandClose: <IconExpandClose />,
                            expandOpen: <IconExpandOpen />,
                            expandAll: <IconExpandOpen />,
                            collapseAll: <IconExpandClose />,
                            parentClose: <IconParentClose />,
                            parentOpen: <IconParrentOpen />,
                            leaf: <IconLeaf />,
                        }}
                    />
                    <div className={style.buttonBox}>
                        <div className={style.flex}>
                            <Button
                                className={classnames('btn', 'btn-default', style.button)}
                                title="Select All"
                                onClick={checkAll}
                            />
                            <Button
                                className={classnames('btn', 'btn-default', style.button)}
                                title="Deselect All"
                                onClick={uncheckAll}
                            />
                        </div>
                        <div className={style.flex}>
                            <Button
                                className={classnames('btn', 'btn-default', style.button)}
                                title="Expand All"
                                onClick={expandAll}
                            />
                            <Button
                                className={classnames('btn', 'btn-default', style.button)}
                                title="Collapse All"
                                onClick={collapseAll}
                            />
                        </div>
                    </div>
                </div>
                <div className={style.modalFooter}>
                    <Button title="Done" className="btn btn-primary" onClick={closeModal} />
                </div>
            </Modal>
        </div>
    );
};

SiteSelector.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    // from redux
    groups: PropTypes.array,
    siteTree: PropTypes.array,
    loadGroups: PropTypes.func,
    loaded: PropTypes.bool,
    setSites: PropTypes.func,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    siteTree: getSiteTree(state.app.sites),
    loaded: state.app.sites.loaded,
});
export default connect(mapStateToProps, {
    loadGroups,
    setSites,
})(SiteSelector);
