import { useEffect } from 'react';
import PropTypes from 'prop-types';

const FilterHack = props => {
    useEffect(() => {
        props.form.setFieldValue(props.field.name, !props.field.value);
    }, []);

    return null;
};

FilterHack.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
};

export default FilterHack;
