import { startCase } from 'lodash';
import React from 'react';
import style from './style.css';
import Skeleton from 'react-loading-skeleton';

export const getGroupCount = (level, sites, groups) => {
    if (level === 'site') {
        return sites.length;
    }
    function recurrsiveAncestoryLookup(node) {
        // base case
        if (!node.group.parentId) {
            return { [node.group.groupType]: node.group.label };
        }
        const parent = groups.find(group => group.group.groupId === node.group.parentId);
        return {
            [node.group.groupType]: node.group.label,
            ...(node.group.groupType !== level && recurrsiveAncestoryLookup(parent)),
        };
    }

    const siteTable = groups
        .filter(group => sites.includes(group.group.groupId))
        .map(group => ({ ...recurrsiveAncestoryLookup(group), id: group.group.groupId }));
    return new Set(siteTable.map(s => s[level] && s[level]).filter(x => x !== undefined)).size;
};

export const groupChildren = {
    site: [{ name: 'site', label: 'sites' }],
    agency: [
        { name: 'agency', label: 'agencies' },
        { name: 'site', label: 'sites' },
    ],
    organization: [
        { name: 'organization', label: 'organizations' },
        { name: 'agency', label: 'agencies' },
        { name: 'site', label: 'sites' },
    ],
    collaborator: [
        { name: 'collaborator', label: 'collaborators' },
        { name: 'organization', label: 'organizations' },
        { name: 'agency', label: 'agencies' },
        { name: 'site', label: 'sites' },
    ],
};

export const pieLegend = [
    {
        anchor: 'top-left',
        direction: 'column',
        justify: false,
        translateX: -200,
        translateY: 0,
        itemWidth: 100,
        itemHeight: 20,
        itemsSpacing: 0,
        symbolSize: 20,
        itemDirection: 'left-to-right',
    },
];

export const lineLegend = [
    {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 110,
        translateY: 0,
        itemWidth: 80,
        itemHeight: 20,
        itemsSpacing: 0,
        symbolSize: 20,
        itemDirection: 'left-to-right',
    },
];

export const renderTitle = (title, color) => (
    <>
        <h4 style={{ color }} className={style.sectionTitle}>
            {title}
        </h4>
        <hr className={style.sectionRule} />
    </>
);

export const renderDesc = desc => <p className={style.desc}>{desc}</p>;

export const renderSubtitle = title => (
    <>
        <h5 className={style.sectionSubtitle}>{title}</h5>
        <hr className={style.sectionRule} />
    </>
);

export const showChart = (component, loading) => (
    <div className={style.margin}>{loading ? <Skeleton height={300} /> : component}</div>
);
export const showTable = (component, loading) => (
    <div className={style.margin}>{loading ? <Skeleton count={15} /> : component}</div>
);
export const renderDescription = text => <div className={style.desc}>{text}</div>;
export const hiddenChart = text => <div className={style.noChart}>{text}</div>;

export const getGroupLevelMap = (groups, groupLevel, siteSelectorField) => {
    function recurrsiveAncestoryLookup(node) {
        // base case
        if (!node.group.parentId) {
            return { [node.group.groupType]: node.group.label };
        }
        const parent = groups.find(group => group.group.groupId === node.group.parentId);
        return {
            [node.group.groupType]: node.group.label,
            ...(node.group.groupType !== groupLevel && recurrsiveAncestoryLookup(parent)),
        };
    }

    const siteTable = groups
        .filter(
            group =>
                group.group.groupType === 'site' && siteSelectorField.includes(group.group.groupId)
        )
        .map(group => ({ ...recurrsiveAncestoryLookup(group), id: group.group.groupId }));
    let levelMap = {};
    for (var site of siteTable) {
        if (site[groupLevel]) {
            if (site[groupLevel] in levelMap) {
                levelMap[site[groupLevel]].push(site.id);
            } else {
                levelMap[site[groupLevel]] = [site.id];
            }
        }
    }
    return levelMap;
};

// VARIABLES
export const monthsInWraparoundVars = filters => {
    const { siteSelectorField, dateSelectorTo, evalCycleSelectorField } = filters;
    return {
        siteIds: siteSelectorField || [],
        deploymentIds: evalCycleSelectorField || [],
        dateTo: dateSelectorTo || '',
    };
};

export const demographicsVars = (filters, alt) => ({
    deploymentIds: filters.evalCycleSelectorField || [],
});

// data conversions
export const convertMonthsInWraparound = (data, filters) => {
    const underflow = '0+';
    const overflow = { int: 24, string: '24+' };
    var monthMap = {};
    for (var youth of data.roles.youthList) {
        const { dateDiffMonths } = youth;
        if (parseInt(dateDiffMonths, 10) >= overflow.int) {
            if (monthMap[overflow.string]) {
                monthMap[overflow.string] += 1;
            } else {
                monthMap[overflow.string] = 1;
            }
        } else if (monthMap[dateDiffMonths]) {
            monthMap[dateDiffMonths] += 1;
        } else {
            monthMap[dateDiffMonths] = 1;
        }
    }
    return Object.keys(monthMap).map(a => ({ id: a === '0' ? underflow : a, count: monthMap[a] }));
};

export const convertDischargeSuccessRate = (data, filters) => {
    var rates = {
        complete: 0,
        incomplete: 0,
    };
    for (var youth of data.roles.youthList) {
        const { exitReason } = youth.fields;
        if (exitReason && exitReason.startsWith('complete')) {
            rates.complete++;
        } else if (exitReason) {
            rates.incomplete++;
        }
    }
    const totalCount = rates.incomplete + rates.complete;
    const ratesCalc = Object.keys(rates).map(a => ({
        id: startCase(a),
        label: `${startCase(a)} (${
            rates[a] > 0 ? ((rates[a] / totalCount) * 100).toFixed(2) : 0
        }%)`,
        value: `${rates[a] || 0}`,
    }));
    return ratesCalc;
};

export const convertDemographicsChart = (data, filters) => {
    let counts = {};
    let totalCount = 0;
    for (var o = 0; o < Object.keys(data.roles).length - 1; o++) {
        const key = Object.keys(data.roles)[o];
        if (key !== 'youthList') {
            const val = data.roles[key];
            totalCount += val;
            counts[key] = val || counts[key] + val;
        }
    }
    return Object.keys(counts).map(a => ({
        id: startCase(a),
        label: `${startCase(a)} (${
            counts[a] > 0 ? ((counts[a] / totalCount) * 100).toFixed(2) : 0
        }%)`,
        value: `${counts[a] || 0}`,
    }));
};

export const convertDemographicsBarChart = (data, filters) => {
    let counts = {};
    for (var o = 0; o < Object.keys(data.roles).length - 1; o++) {
        const key = Object.keys(data.roles)[o];
        if (key !== 'youthList') {
            const val = data.roles[key];
            counts[key] = val || counts[key] + val;
        }
    }
    return Object.keys(counts).map(a => ({
        id: startCase(a),
        count: counts[a] || 0,
    }));
};

export const convertExitReasonBarChart = (data, filters) => {
    const d = data.roles.youthSummary.frequency
        ? data.roles.youthSummary.frequency
              .map(a => ({
                  id: a.value,
                  count: a.valueCount,
              }))
              .filter(item => item !== false)
        : [];
    const orderLabelMap = {
        completedMetGoalsPOCSteppedDown: 'Compl. Step-down',
        completedMetGoalsPOCDischarged: 'Compl. Met Goals on POC',
        incompleteNoEngagement: 'Inc. No Engagement',
        incompleteFamilyChoice: 'Inc. Family Choice',
        incompleteHigherLevelOfCare: 'Inc. Higher Level of Care',
        incompleteExcludedSetting: 'Inc. Excluded Setting',
        incompleteDetention: 'Inc. Detention',
        ineligibleAgedOut: 'Inel. Aged Out',
        ineligibleLossOfInsurance: 'Inel. Loss of Insurance',
        ineligibleChangeInIncome: 'Inel. Change in Income',
        relocated: 'Relocated',
        other: 'Other',
    };
    var chartData = [];
    for (var col of Object.keys(orderLabelMap)) {
        var count = 0;
        const item = d.find(o => o.id === col);
        if (col === 'other') {
            // include null and '' results
            var nullcount = d.find(o => o.id === null);
            var emptycount = d.find(o => o.id === '');
            if (nullcount) {
                count += nullcount.count;
            }
            if (emptycount) {
                count += emptycount.count;
            }
        }
        if (col === 'other' || item) {
            count += item ? item.count : 0;
            chartData.push({ id: orderLabelMap[col], count });
        }
    }
    return chartData;
};

export const findAncestoryGroups = (siteIds, groupLevel, groups) => {
    function recurrsiveAncestorLookup(node) {
        // base case
        if (node.group.groupType === groupLevel) {
            return node.group.groupId;
        }
        const parent = groups.find(group => group.group.groupId === node.group.parentId);
        if (parent) {
            return recurrsiveAncestorLookup(parent);
        }
        return false;
    }
    let ancestorIds = [];
    for (var siteId of siteIds) {
        const gId = recurrsiveAncestorLookup(groups.find(g => g.group.groupId === siteId));
        if (gId) {
            ancestorIds.push(gId);
        }
    }
    return ancestorIds;
};

export const convertAgeAtEnrollment = data => {
    const underflow = '0+';
    const overflow = '19+';
    const sortedKeys = [
        '0+',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19+',
    ];
    const freq = {
        [underflow]: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        [overflow]: 0,
    };
    var siteFreq = data.roles.youthSummary.frequency;
    for (var o = 0; o < siteFreq.length; o++) {
        var count = siteFreq[o];
        var age = parseInt(count.value, 10);
        if (age > 18) {
            freq[overflow] += count.valueCount;
        }
        if (age < 1) {
            freq[underflow] += count.valueCount;
        } else if (Object.keys(freq).includes(count.value)) {
            freq[count.value] += count.valueCount;
        }
    }
    return sortedKeys.map(a => ({ id: a, count: freq[a] }));
};
