import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import classnames from 'classnames';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import IconSelect from 'cccisd-icons/stack-check';
import IconCheck from 'cccisd-icons/checkbox-checked2';
import IconUncheck from 'cccisd-icons/checkbox-unchecked2';
import IconHalfcheck from 'cccisd-icons/checkbox-partial2';
import IconExpandClose from 'cccisd-icons/arrow-right';
import IconExpandOpen from 'cccisd-icons/arrow-down';
import IconParentClose from 'cccisd-icons/folder2';
import IconParrentOpen from 'cccisd-icons/folder-open';
import IconLeaf from 'cccisd-icons/earth2';
import { connect } from 'react-redux';
import { setCareCoords } from 'js/reducers/report.js';
import { getSiteTree } from 'js/selectors/sites.js';
import style from './style.css';
import Button from 'cccisd-click-button';
import hash from 'object-hash';

const CareCoordSelector = props => {
    const modal = useRef(null);
    const [checked, setChecked] = useState([]); // useState(props.careCoords?.map(c => c.value));
    const [nodes, setNodes] = useState([]);
    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        checkAll();
        setSelection();
    }, [hash(props.careCoords)]);

    useEffect(() => {
        getNodes();
    }, [props.careCoords, props.sites]);

    useEffect(() => {
        checkAll();
    }, [hash(nodes)]);

    useEffect(() => {
        setSelection();
    }, [checked]);

    const setSelection = () => {
        let selection = checked;
        if (selection.length === 0) {
            selection = [null];
        }
        props.form.setFieldValue('careCoordSelectorField', selection);
    };

    const getLabel = () => `Select Care Coordinators (${checked?.length})`;

    const handleCheck = c => {
        setChecked(c);
    };

    const checkAll = () => {
        setChecked(nodes?.map(c => c.value));
    };

    const uncheckAll = () => {
        setChecked([]);
    };

    const closeModal = () => {
        modal.current.close();
    };

    const getNodes = () => {
        const n = [];
        for (const c of props?.careCoords) {
            if (
                c?.siteIds.some(s => props.sites.includes(s) || props.sites.includes(s.toString()))
            ) {
                n.push({ value: c.key, label: c.label });
            }
        }
        setNodes(n);
    };

    return (
        <div className={style.flex}>
            <Modal
                ref={modal}
                size="large"
                trigger={
                    <button type="button" className={classnames('btn', 'btn-default')}>
                        <IconSelect spaceRight />
                        {getLabel()}
                    </button>
                }
                title={getLabel()}
                beforeClose={setSelection}
            >
                {nodes?.length > 0 ? (
                    <div className={style.flex}>
                        <CheckboxTree
                            nodes={nodes}
                            checked={checked}
                            expanded={expanded}
                            onCheck={c => handleCheck(c)}
                            onExpand={e => setExpanded(e)}
                            icons={{
                                check: <IconCheck />,
                                uncheck: <IconUncheck />,
                                halfCheck: <IconHalfcheck />,
                                expandClose: <IconExpandClose />,
                                expandOpen: <IconExpandOpen />,
                                expandAll: <IconExpandOpen />,
                                collapseAll: <IconExpandClose />,
                                parentClose: <IconParentClose />,
                                parentOpen: <IconParrentOpen />,
                                leaf: <IconLeaf />,
                            }}
                        />
                        <div className={style.buttonBox}>
                            <div className={style.flex}>
                                <Button
                                    className={classnames('btn', 'btn-default', style.button)}
                                    title="Select All"
                                    onClick={checkAll}
                                />
                                <Button
                                    className={classnames('btn', 'btn-default', style.button)}
                                    title="Deselect All"
                                    onClick={uncheckAll}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <h4>
                            There are no care coordinators with evaluations cycles for the sites
                            selected
                        </h4>
                        <h6>Try selecting different sites</h6>
                    </>
                )}
                <div className={style.modalFooter}>
                    <Button title="Done" className="btn btn-primary" onClick={closeModal} />
                </div>
            </Modal>
        </div>
    );
};

CareCoordSelector.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    // from redux
    careCoords: PropTypes.array,
    ccoYouthMap: PropTypes.object,
    sites: PropTypes.array,
    setCareCoords: PropTypes.func,
};

const mapStateToProps = state => ({
    careCoords: state.app.report.careCoords,
    sites: state.app.report.sites,
    siteTree: getSiteTree(state.app.sites),
    ccoYouthMap: state.app.report.ccoYouthMap,
});

export default connect(mapStateToProps, { setCareCoords })(CareCoordSelector);
