import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import IconPencil from 'cccisd-icons/pencil';
import AdminForm from 'js/components/AdminForm';
import Tooltip from 'cccisd-tooltip';
import axios from 'cccisd-axios';
import { connect } from 'react-redux';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

class Component extends React.Component {
    static propTypes = {
        row: PropTypes.object,
        settings: PropTypes.object,
        loadData: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.modal = React.createRef();
    }

    onSubmit = async values => {
        const result = await axios.put(
            Boilerplate.route('api.nexus.pawn.update', { pawn: this.props.row['pawn.pawnId'] }),
            {
                first_name: values.firstName,
                last_name: values.lastName,
                username: values.email,
                email: values.email,
                adminId: values.adminId,
                group: values.group,
                adminLabel: values.adminLabel,
                permissions: JSON.stringify(values.permissions),
                toolPermissions: values.toolPerms === [] ? null : JSON.stringify(values.toolPerms),
                role: this.props.settings.adminType,
                adminType: this.props.settings.adminType,
                supervisor: values.supervisor,
                pawnHash: this.props.row['pawn.pawnHash'],
            }
        );

        if (result.data.errors) {
            throw result.data.errors;
        }

        await this.props.loadData();
        this.modal.current.close();
    };

    getInitialValues = () => {
        const { row, settings } = this.props;

        return {
            firstName: row['user.firstName'],
            lastName: row['user.lastName'],
            email: row['user.username'],
            adminId: row['fields.adminId'],
            group: row[settings.groupPath],
            adminLabel: row['fields.adminLabel'],
            permissions: JSON.parse(row['fields.permissions']),
            supervisor: row['fields.supervisor'],
            toolPerms: JSON.parse(row['fields.toolPermissions']),
        };
    };

    render() {
        return (
            <Modal
                ref={this.modal}
                trigger={
                    <Tooltip title="Edit">
                        <button type="button" className="btn btn-xs btn-success">
                            <IconPencil />
                        </button>
                    </Tooltip>
                }
                title="Edit Admin"
            >
                <AdminForm
                    onSubmit={this.onSubmit}
                    initialValues={this.getInitialValues()}
                    operation="edit"
                    {...this.props}
                />
            </Modal>
        );
    }
}

const mapStateToProps = (state, props) => ({
    // modules: state.app.admin.modules,
});

export default connect(mapStateToProps)(Component);
