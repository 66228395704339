import React from 'react';
import PropTypes from 'prop-types';
import IconInfo from 'cccisd-icons/info';
import classnames from 'classnames';
import style from './style.css';

const InfoBanner = props => {
    return (
        <div className={style.banner}>
            <span className={classnames(style.icon, 'text-primary')}>
                <IconInfo />
            </span>
            {props.text}
        </div>
    );
};

InfoBanner.propTypes = {
    text: PropTypes.string,
};

export default InfoBanner;
