import {
    renderDesc,
    renderTitle,
    showTable,
    findAncestoryGroups,
} from 'js/vendor/reports/helpers.js';
import React, { useState, useEffect } from 'react';
import Table from 'cccisd-table';
import PropTypes from 'prop-types';
import { client } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { loadGroups } from 'js/reducers/sites.js';
import { round } from 'lodash';
import hash from 'object-hash';
import flatten from 'flat';
import { queryMap } from './vars.js';

function SystemInvolvementSection(props) {
    const groupLevel = props.groupLevel || 'group';
    const [loading, setLoading] = useState(true);
    const [dataANDDischarged, setANDDischarged] = useState([]);
    const [dataANDDischargedMean, setANDDischargedMean] = useState([]);
    const [dataAll, setAll] = useState([]);
    const [dataAllMean, setAllMean] = useState([]);

    const dataMap = {
        ANDDischarged: { setter: setANDDischarged, data: dataANDDischarged },
        All: { setter: setAll, data: dataAll },
        ANDDischargedMeans: { setter: setANDDischargedMean, data: dataANDDischargedMean },
        AllMeans: { setter: setAllMean, data: dataAllMean },
    };

    useEffect(() => {
        if (props.groups.length > 0 && props.youthPawnIds) {
            getData();
        }
    }, [props.filters.siteSelectorField, props.youthPawnIds]);

    const renderCell = (count, n) => {
        return `${count || 0} (${round((count / n) * 100, 2) || 0}%) N=${n || 0}`;
    };

    const renderMeanCell = avg => {
        return `${avg === null ? 0 : round(avg, 2)}`;
    };

    const commonCols = [
        {
            name: 'group.label',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            filter: true,
            sort: true,
        },
        {
            name: 'descendantRoles.youthCount',
            label: 'Number of Assigned Youth',
        },
    ];
    const sysCols = [
        {
            name: 'descendantRoles.JJEnr',
            label: 'Enrollment',
            topHeader: 'Previous or Current Juvenile Justice Involvement',
            topHeaderSpan: 2,
            render: (value, row) =>
                renderCell(row['descendantRoles.JJEnrCount'], row['descendantRoles.JJEnrN']),
        },
        {
            name: 'descendantRoles.JJDis',
            label: 'Discharge',
            render: (value, row) =>
                renderCell(row['descendantRoles.JJDisCount'], row['descendantRoles.JJDisN']),
        },
        {
            name: 'descendantRoles.CWEnr',
            label: 'Enrollment',
            topHeader: 'Previous or Current Child Welfare Involvement',
            topHeaderSpan: 2,
            render: (value, row) =>
                renderCell(row['descendantRoles.CWEnrCount'], row['descendantRoles.CWEnrN']),
        },
        {
            name: 'descendantRoles.CWDis',
            label: 'Discharge',
            render: (value, row) =>
                renderCell(row['descendantRoles.CWDisCount'], row['descendantRoles.CWDisN']),
        },
        {
            name: 'descendantRoles.ESEnr',
            label: 'Enrollment',
            topHeader: 'History of Expulsions/Suspensions from School',
            topHeaderSpan: 2,
            render: (value, row) =>
                renderCell(row['descendantRoles.ESEnrCount'], row['descendantRoles.ESEnrN']),
        },
        {
            name: 'descendantRoles.ESDis',
            label: 'Discharge',
            render: (value, row) =>
                renderCell(row['descendantRoles.ESDisCount'], row['descendantRoles.ESDisN']),
        },
        {
            name: 'descendantRoles.CPEnr',
            label: 'Enrollment',
            topHeader: 'History of Out of Community Placement',
            topHeaderSpan: 2,
            render: (value, row) =>
                renderCell(row['descendantRoles.CPEnrCount'], row['descendantRoles.CPEnrN']),
        },
        {
            name: 'descendantRoles.CPDis',
            label: 'Discharge',
            render: (value, row) =>
                renderCell(row['descendantRoles.CPDisCount'], row['descendantRoles.CPDisN']),
        },
        {
            name: 'descendantRoles.EREnr',
            label: 'Enrollment',
            topHeader: 'History of Emergency Room Visits for Mental Health',
            topHeaderSpan: 2,
            render: (value, row) =>
                renderCell(row['descendantRoles.EREnrCount'], row['descendantRoles.EREnrN']),
        },
        {
            name: 'descendantRoles.ERDis',
            label: 'Discharge',
            render: (value, row) =>
                renderCell(row['descendantRoles.ERDisCount'], row['descendantRoles.ERDisN']),
        },
        {
            name: 'descendantRoles.RUEnr',
            label: 'Enrollment',
            topHeader: 'History of Runaways',
            topHeaderSpan: 2,
            render: (value, row) =>
                renderCell(row['descendantRoles.RUEnrCount'], row['descendantRoles.RUEnrN']),
        },
        {
            name: 'descendantRoles.RUDis',
            label: 'Discharge',
            render: (value, row) =>
                renderCell(row['descendantRoles.RUDisCount'], row['descendantRoles.RUDisN']),
        },
    ];

    const meanCols = [
        {
            name: 'descendantRoles.arrestsEnroll',
            label: 'Enrollment',
            topHeader: 'Mean Arrests',
            topHeaderTooltip:
                'Average number of arrests for youth with previous or current juvenile justice involvement. If blank there were no youth with recorded arrests.',
            topHeaderSpan: 2,
            render: (value, row) => renderMeanCell(row['descendantRoles.arrestsEnroll.avg']),
        },
        {
            name: 'descendantRoles.arrestsDischarge',
            label: 'Discharge',
            render: (value, row) => renderMeanCell(row['descendantRoles.arrestsDischarge.avg']),
        },
        {
            name: 'descendantRoles.expulsionsEnroll',
            label: 'Enrollment',
            topHeader: 'Mean Expulsions/Suspensions',
            topHeaderTooltip:
                'Average number of expulsions/suspensions for youth with a history of expulsions/suspensions from school. If blank there were no youth with recorded expulsions/suspensions.',
            topHeaderSpan: 2,
            render: (value, row) => renderMeanCell(row['descendantRoles.expulsionsEnroll.avg']),
        },
        {
            name: 'descendantRoles.expulsionsDischarge',
            label: 'Discharge',
            render: (value, row) => renderMeanCell(row['descendantRoles.expulsionsDischarge.avg']),
        },
        {
            name: 'descendantRoles.suspensionsEnroll',
            label: 'Enrollment',
            topHeader: 'Mean Days Expelled/Suspended',
            topHeaderTooltip:
                'Average number of days youth was expelled/suspended from school. If blank there were no youth with recorded days of expulsions/suspensions.',
            topHeaderSpan: 2,
            render: (value, row) => renderMeanCell(row['descendantRoles.suspensionsEnroll.avg']),
        },
        {
            name: 'descendantRoles.suspensionsDischarge',
            label: 'Discharge',
            render: (value, row) => renderMeanCell(row['descendantRoles.suspensionsDischarge.avg']),
        },
        {
            name: 'descendantRoles.outCommunityEnroll',
            label: 'Enrollment',
            topHeader: 'Mean Out of Community Placements',
            topHeaderTooltip:
                'Average number of hospitalizations for mental health for youth with a history of hospitalizations. If blank there were no youth with recorded hospitalizations.',
            topHeaderSpan: 2,
            render: (value, row) => renderMeanCell(row['descendantRoles.outCommunityEnroll.avg']),
        },
        {
            name: 'descendantRoles.outCommunityDischarge',
            label: 'Discharge',
            render: (value, row) =>
                renderMeanCell(row['descendantRoles.outCommunityDischarge.avg']),
        },
        {
            name: 'descendantRoles.ERVisitsEnroll',
            label: 'Enrollment',
            topHeader: 'Mean ER Visits for Mental Health',
            topHeaderTooltip:
                'Average number of hospitalizations for mental health for youth with a history of hospitalizations. If blank there were no youth with recorded hospitalizations.',
            topHeaderSpan: 2,
            render: (value, row) => renderMeanCell(row['descendantRoles.ERVisitsEnroll.avg']),
        },
        {
            name: 'descendantRoles.ERVisitsDischarge',
            label: 'Discharge',
            render: (value, row) => renderMeanCell(row['descendantRoles.ERVisitsDischarge.avg']),
        },
        {
            name: 'descendantRoles.runawaysEnroll',
            label: 'Enrollment',
            topHeader: 'Mean Runaways',
            topHeaderTooltip:
                'Average number of runaways for youth with a history of runaways. If blank there were no youth with recorded runaways.',
            topHeaderSpan: 2,
            render: (value, row) => renderMeanCell(row['descendantRoles.runawaysEnroll.avg']),
        },
        {
            name: 'descendantRoles.ERVisitsDischarge',
            label: 'Discharge',
            render: (value, row) => renderMeanCell(row['descendantRoles.ERVisitsDischarge.avg']),
        },
    ];

    const getCols = (totalCols, index, numTables) => {
        let numCols = Math.floor(totalCols.length / numTables);
        const start = 0 + index * numCols;
        const tableCols = totalCols.slice(start, start + numCols);
        return [...commonCols, ...tableCols];
    };

    const tables = [
        {
            title: 'Community and System Involvement at Enrollment and Discharge for All Youth',
            desc:
                'Number and percent of all youth for whom data is available who experienced the following home, school, and community outcomes. Includes youth with missing data at either time-point or who have not yet discharged.',
            cols: getCols(sysCols, 0, 3),
            name: 'CountAND1',
            queryHandle: 'All',
        },
        {
            title:
                'Community and System Involvement at Enrollment and Discharge for All Youth (Continued Pt. 2)',
            desc: null,
            cols: getCols(sysCols, 1, 3),
            name: 'CountAND2',
            queryHandle: 'All',
        },
        {
            title:
                'Community and System Involvement at Enrollment and Discharge for All Youth (Continued Pt. 3)',
            desc: null,
            cols: getCols(sysCols, 2, 3),
            name: 'CountAND3',
            queryHandle: 'All',
        },
        {
            title:
                'Community and System Involvement at Enrollment or Discharge for Discharged Youth',
            desc:
                'Number and percent of youth with data available at both baseline and discharge who experienced the following home, school, and community outcomes.',
            cols: getCols(sysCols, 0, 3),
            name: 'CountOR1',
            queryHandle: 'ANDDischarged',
        },
        {
            title:
                'Community and System Involvement at Enrollment or Discharge for Discharged Youth (Continued Pt. 2)',
            desc: null,
            cols: getCols(sysCols, 1, 3),
            name: 'CountOR2',
            queryHandle: 'ANDDischarged',
        },
        {
            title:
                'Community and System Involvement at Enrollment or Discharge for Discharged Youth (Continued Pt. 3)',
            desc: null,
            cols: getCols(sysCols, 2, 3),
            name: 'CountOR3',
            queryHandle: 'ANDDischarged',
        },
        {
            title:
                'Community and System Involvement Means at Enrollment and Discharge for All Youth',
            desc:
                'The average number of occurrences of enrolled youth for whom each outcome was reported in the 6 months prior to enrollment and discharge. Presents these averages for all youth for whom data is available at either time-point (including youth with missing data at either time-point or who have not yet discharged).',
            cols: getCols(meanCols, 0, 3),
            name: 'MeanAND1',
            queryHandle: 'AllMeans',
        },

        {
            title:
                'Community and System Involvement Means at Enrollment and Discharge for All Youth (Continued Pt. 2)',
            desc: null,
            cols: getCols(meanCols, 1, 3),
            name: 'MeanAND2',
            queryHandle: 'AllMeans',
        },
        {
            title:
                'Community and System Involvement Means at Enrollment and Discharge for All Youth (Continued Pt. 3)',
            desc: null,
            cols: getCols(meanCols, 2, 3),
            name: 'MeanAND3',
            queryHandle: 'AllMeans',
        },
        {
            title:
                'Community and System Involvement Means at Enrollment or Discharge for Discharged Youth',
            desc:
                'The average number of occurrences of enrolled youth for whom each outcome was reported in the 6 months prior to enrollment and discharge. Presents these averages only for youth with complete data at both enrollment and discharge.',
            cols: getCols(meanCols, 0, 3),
            name: 'MeanOR1',
            queryHandle: 'ANDDischargedMeans',
        },
        {
            title:
                'Community and System Involvement Means at Enrollment or Discharge for Discharged Youth (Continued Pt. 2)',
            desc: null,
            cols: getCols(meanCols, 1, 3),
            name: 'MeanOR2',
            queryHandle: 'ANDDischargedMeans',
        },
        {
            title:
                'Community and System Involvement Means at Enrollment or Discharge for Discharged Youth (Continued Pt. 3)',
            desc: null,
            cols: getCols(meanCols, 2, 3),
            name: 'MeanOR3',
            queryHandle: 'ANDDischargedMeans',
        },
    ];

    const getData = async () => {
        let queriesToRun = [];
        for (var table of tables) {
            if (!queriesToRun.includes(table.queryHandle)) {
                queriesToRun.push(table.queryHandle);
            }
        }
        await Promise.all(
            queriesToRun.map(handle =>
                client
                    .query({
                        query: queryMap[groupLevel][handle],
                        variables: {
                            pawnIds: props.youthPawnIds || [],
                            groupIds: findAncestoryGroups(
                                props.filters.siteSelectorField,
                                'site',
                                props.groups
                            ),
                        },
                        fetchPolicy: 'network-only',
                        errorPolicy: 'all',
                    })
                    .then(response => {
                        if (response.data) {
                            const ddd = response.data.groups[groupLevel + 'List']
                                .filter(s => s.descendantRoles.youthCount !== 0)
                                .map(s => flatten(s));
                            dataMap[handle].setter(ddd);
                        }
                    })
            )
        );
        setLoading(false);
        props.setWidgetLoaded(props.section.name + 'Section');
    };

    const getFoot = (data, cols, isMean) => {
        let totals = {};
        for (var row of data) {
            for (var cell of Object.keys(row)) {
                if (parseInt(row[cell], 10)) {
                    if (totals[cell]) {
                        totals[cell] += row[cell];
                    } else {
                        totals[cell] = row[cell];
                    }
                }
            }
        }
        let totalRow = {
            'group.label': 'Total:',
            'descendantRoles.youthCount': totals['descendantRoles.youthCount'],
        };
        for (var col of cols) {
            if (
                !['group.groupId', 'group.label', 'descendantRoles.youthCount'].includes(col.name)
            ) {
                if (isMean) {
                    const numRowsWithValue = data.filter(r => r[col.name + '.avg']).length;
                    totalRow[col.name] = renderMeanCell(
                        totals[col.name + '.avg'] / numRowsWithValue || 0
                    );
                } else {
                    totalRow[col.name] = renderCell(
                        totals[col.name + 'Count'],
                        totals[col.name + 'N']
                    );
                }
            }
        }
        return totalRow;
    };

    return tables.map(table => (
        <div key={hash(table)}>
            {renderTitle(table.title, table.title.includes('Continued') && '#000')}
            {renderDesc(table.desc)}
            {showTable(
                <Table
                    isCsvDownload
                    data={dataMap[table.queryHandle].data}
                    columns={table.cols}
                    rowKey="group.label"
                    showPerPageOptions={false}
                    footer={
                        !loading &&
                        getFoot(
                            dataMap[table.queryHandle].data,
                            table.cols,
                            table.name.includes('Mean')
                        )
                    }
                />,
                loading
            )}
        </div>
    ));
}

SystemInvolvementSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    loadGroups: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded, loadGroups })(SystemInvolvementSection);
