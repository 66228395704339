import React, { useState } from 'react';
import PropTypes from 'prop-types';

const HistoricalFilter = props => {
    const [value, setValue] = useState(props.field.value);

    const getLabel = months => {
        return `${months} MO`;
    };

    const onChange = (e, option) => {
        e.preventDefault();
        setValue(option);
        props.form.setFieldValue(props.field.name, option);
    };

    return (
        <ul className="pagination" style={{ display: 'flex', margin: '0em' }}>
            {props.options.map(option => (
                <li className={value === option ? 'active' : ''} key={option}>
                    <a href="#" onClick={e => onChange(e, option)}>
                        {getLabel(option)}
                    </a>
                </li>
            ))}
        </ul>
    );
};

HistoricalFilter.propTypes = {
    options: PropTypes.array,
    form: PropTypes.object,
    field: PropTypes.object,
};
export default HistoricalFilter;
