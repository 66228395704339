import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'cccisd-tooltip';
import IconInfo from 'cccisd-icons/info2';
import style from './style.css';

const InfoTooltip = props => {
    return (
        <div className={style.moveIt}>
            <Tooltip title={props.text}>
                <span className="text-primary">
                    <IconInfo />
                </span>
            </Tooltip>
        </div>
    );
};

InfoTooltip.propTypes = {
    text: PropTypes.string,
};

export default InfoTooltip;
