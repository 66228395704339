import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import classnames from 'classnames';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import CccisdToggle from 'cccisd-toggle';
import style from './style.css';
import Button from 'cccisd-click-button';
import IconCheck from 'cccisd-icons/checkmark';

export default class ReportViewSelector extends React.Component {
    static propTypes = {
        form: PropTypes.object,
        field: PropTypes.object,
        views: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.modal = React.createRef();
    }

    componentDidMount = () => {
        this.checkAll();
    };

    handleChange = v => {
        if (this.props.form.values[this.props.field.name].includes(v)) {
            this.props.form.setFieldValue(
                this.props.field.name,
                this.props.form.values[this.props.field.name].filter(s => s !== v)
            );
        } else {
            this.props.form.setFieldValue(this.props.field.name, [
                v,
                ...this.props.form.values[this.props.field.name],
            ]);
        }
    };

    checkAll = () => {
        this.props.form.setFieldValue(this.props.field.name, this.props.views);
    };

    getLabel = () => {
        const count =
            this.props.views.length === this.props.form.values[this.props.field.name].length
                ? 'All'
                : this.props.form.values[this.props.field.name].length +
                  '/' +
                  this.props.views.length;
        return 'Report Sections (' + count + ')';
    };

    closeModal = () => {
        this.modal.current.close();
    };

    render() {
        return (
            <Modal
                ref={this.modal}
                trigger={
                    <button type="button" className={classnames('btn', 'btn-default')}>
                        <IconCheck spaceRight />

                        {this.getLabel()}
                    </button>
                }
                title={this.getLabel()}
            >
                <div>
                    {this.props.views.map(v => (
                        <div className={style.marginBottom} key={v}>
                            <CccisdToggle
                                value={this.props.form.values[this.props.field.name].includes(v)}
                                onChange={() => this.handleChange(v)}
                                label={v}
                            />
                        </div>
                    ))}
                </div>
                <div className={style.modalFooter}>
                    <Button title="Done" className="btn btn-primary" onClick={this.closeModal} />
                </div>
            </Modal>
        );
    }
}
