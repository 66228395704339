import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import classnames from 'classnames';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import IconSelect from 'cccisd-icons/stack-check';
import IconCheck from 'cccisd-icons/checkbox-checked2';
import IconUncheck from 'cccisd-icons/checkbox-unchecked2';
import IconHalfcheck from 'cccisd-icons/checkbox-partial2';
import IconExpandClose from 'cccisd-icons/arrow-right';
import IconExpandOpen from 'cccisd-icons/arrow-down';
import IconParentClose from 'cccisd-icons/folder2';
import IconParrentOpen from 'cccisd-icons/folder-open';
import IconLeaf from 'cccisd-icons/earth2';
import { connect } from 'react-redux';
import { setCycles } from 'js/reducers/report.js';
import moment from 'moment';
import hash from 'object-hash';

import style from './style.css';
import Button from 'cccisd-click-button';

const EvalCycleSelector = props => {
    const modal = useRef(null);
    const { siteSelectorField, respondentSelectorField, teamMemberSelectorField, dateSelectorFrom, dateSelectorTo } =
        props.form.values;
    const [checked, setChecked] = useState(props.cycles.map(c => c.value));
    const [cycles, setLocalCycles] = useState([]);
    const prevSitesRef = useRef();

    // only reselect cycles if the sites have changed -- not just the ordering of sites
    useEffect(() => {
        if (prevSitesRef.current && hash(prevSitesRef.current) !== hash(siteSelectorField)) {
            filterCycles();
        }
        prevSitesRef.current = siteSelectorField;
    }, [siteSelectorField]);

    useEffect(() => {
        filterCycles();
    }, [dateSelectorFrom, dateSelectorTo, respondentSelectorField, teamMemberSelectorField]);

    const filterCycles = () => {
        const now = moment();
        const minsOld = now.startOf('day').diff(dateSelectorFrom, 'minutes');
        const minsYoung = now.startOf('day').diff(dateSelectorTo, 'minutes');
        const adjustedTeamMemberSelectorField =
            teamMemberSelectorField &&
            teamMemberSelectorField.map(v => {
                if (v === 'parentSupportPartner/PeerProfessional') {
                    return 'parentSupportPartner/PeerProfess';
                }
                if (v === null) {
                    return ''; // graphql will turn null into ''
                }
                return v;
            });
        const options = props.cycles.filter(d => {
            const openedMinsAgo = now.diff(d.openedDate, 'minutes');
            const closedMinsAgo = now.diff(d.closedDate, 'minutes');

            let allTeamMembersForCycle = false;
            if (d.relationshipToYouth) {
                allTeamMembersForCycle = d.relationshipToYouth.split(',').map(r => r.trim());
            }
            if (!siteSelectorField.includes(parseInt(d.groupId, 10))) {
                return false;
            }
            if (respondentSelectorField && !respondentSelectorField.includes(d.assignment.assignmentId.toString())) {
                return false;
            }
            if (allTeamMembersForCycle) {
                if (
                    adjustedTeamMemberSelectorField &&
                    !adjustedTeamMemberSelectorField.some(tm => allTeamMembersForCycle.includes(tm))
                ) {
                    return false;
                }
            }
            if (minsYoung > openedMinsAgo) {
                return false;
            }
            if (minsOld < closedMinsAgo) {
                return false;
            }
            return true;
        });
        const vals = options.map(o => o.deploymentId);

        setChecked(vals);
        setLocalCycles(
            options.map(d => ({
                value: d.deploymentId,
                label: d.label,
            }))
        );
        props.form.setFieldValue('evalCycleSelectorField', vals);
    };

    const setSelection = () => {
        let selection = checked;
        if (selection.length === 0) {
            selection = [null];
        } else {
            selection = checked.map(s => parseInt(s, 10));
        }
        props.form.setFieldValue('evalCycleSelectorField', selection);
    };

    const getLabel = () => `Select Cycles (${checked.length})`;

    const handleCheck = c => {
        setChecked(c);
    };

    const checkAll = () => {
        setChecked(cycles.map(c => c.value));
    };

    const uncheckAll = () => {
        setChecked([]);
    };

    const closeModal = () => {
        modal.current.close();
    };

    return (
        <div className={style.flex}>
            <Modal
                ref={modal}
                size="large"
                trigger={
                    <button type="button" className={classnames('btn', 'btn-default')}>
                        <IconSelect spaceRight />
                        {getLabel()}
                    </button>
                }
                title={getLabel()}
                beforeClose={setSelection}
            >
                {props.cycles.length > 0 ? (
                    <div className={style.flex}>
                        <CheckboxTree
                            nodes={cycles}
                            checked={checked}
                            expanded={[]}
                            onCheck={c => handleCheck(c)}
                            onExpand={e => e}
                            icons={{
                                check: <IconCheck />,
                                uncheck: <IconUncheck />,
                                halfCheck: <IconHalfcheck />,
                                expandClose: <IconExpandClose />,
                                expandOpen: <IconExpandOpen />,
                                expandAll: <IconExpandOpen />,
                                collapseAll: <IconExpandClose />,
                                parentClose: <IconParentClose />,
                                parentOpen: <IconParrentOpen />,
                                leaf: <IconLeaf />,
                            }}
                        />
                        <div className={style.buttonBox}>
                            <div className={style.flex}>
                                <Button
                                    className={classnames('btn', 'btn-default', style.button)}
                                    title="Select All"
                                    onClick={checkAll}
                                />
                                <Button
                                    className={classnames('btn', 'btn-default', style.button)}
                                    title="Deselect All"
                                    onClick={uncheckAll}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <h4>There are no cycles that meet the criteria</h4>
                        <h6>Try selecting different filters</h6>
                    </>
                )}
                <div className={style.modalFooter}>
                    <Button title="Done" className="btn btn-primary" onClick={closeModal} />
                </div>
            </Modal>
        </div>
    );
};

EvalCycleSelector.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    // from redux
    sites: PropTypes.array,
    respondents: PropTypes.array,
    teamMemberTypes: PropTypes.array,
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
    setCycles: PropTypes.func,
    cycles: PropTypes.array,
};

const mapStateToProps = state => ({
    sites: state.app.report.sites,
    respondents: state.app.report.respondents,
    teamMemberTypes: state.app.report.teamMemberTypes,
    dateFrom: state.app.report.dateFrom,
    dateTo: state.app.report.dateTo,
    cycles: state.app.report.cycles,
});

export default connect(mapStateToProps, { setCycles })(EvalCycleSelector);
