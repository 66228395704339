import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import style from './style.css';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import IconSelect from 'cccisd-icons/stack-check';
import IconCheck from 'cccisd-icons/checkbox-checked2';
import IconUncheck from 'cccisd-icons/checkbox-unchecked2';
import IconHalfcheck from 'cccisd-icons/checkbox-partial2';
import IconExpandClose from 'cccisd-icons/arrow-right';
import IconExpandOpen from 'cccisd-icons/arrow-down';
import IconParentClose from 'cccisd-icons/folder2';
import IconParrentOpen from 'cccisd-icons/folder-open';
import IconLeaf from 'cccisd-icons/earth2';
import { connect } from 'react-redux';
import { setTeamMemberTypes } from 'js/reducers/report.js';
import Button from 'cccisd-click-button';
import classnames from 'classnames';
import InfoTooltip from 'js/vendor/reports/components/InfoTooltip';

export const teamtypes = window.cccisd.appDefs.pawn.fields
    .find(e => e.handle === 'relationshipToYouth')
    .values.map(e => ({ value: e.value, label: e.name }))
    .concat({ value: null, label: 'Missing relationship' });

const TeamMemberSelector = props => {
    const modal = useRef(null);
    const [checked, setChecked] = useState(teamtypes.map(t => t.value));

    useEffect(() => {
        setSelection();
    }, []);

    const getLabel = () => `Team Member Types (${checked.length})`;
    const closeModal = () => {
        modal.current.close();
    };

    const setSelection = () => {
        let selection = checked;
        if (selection.length === 0) {
            selection = [null];
        } else if (selection.includes('null')) {
            // CheckboxTree likes to use strings so we need to convert back to null
            const index = selection.indexOf('null');
            selection[index] = null;
        }
        props.form.setFieldValue('teamMemberSelectorField', selection);
        props.setTeamMemberTypes(selection);
    };

    const uncheckAll = () => {
        setChecked([]);
    };

    const checkAll = () => {
        setChecked(teamtypes.map(t => t.value));
    };

    // If Team Member not selected in respondent selector
    if (props.respondents && !props.respondents.includes('4')) {
        return null;
    }
    return (
        <div className={style.flex}>
            <Modal
                ref={modal}
                size="large"
                trigger={
                    <button type="button" className={classnames('btn', 'btn-default')}>
                        <IconSelect spaceRight />
                        {getLabel()}
                    </button>
                }
                title={getLabel()}
                beforeClose={setSelection}
            >
                <div className={style.flex}>
                    <CheckboxTree
                        nodes={teamtypes}
                        checked={checked}
                        expanded={[]}
                        onCheck={c => setChecked(c)}
                        onExpand={e => e}
                        icons={{
                            check: <IconCheck />,
                            uncheck: <IconUncheck />,
                            halfCheck: <IconHalfcheck />,
                            expandClose: <IconExpandClose />,
                            expandOpen: <IconExpandOpen />,
                            expandAll: <IconExpandOpen />,
                            collapseAll: <IconExpandClose />,
                            parentClose: <IconParentClose />,
                            parentOpen: <IconParrentOpen />,
                            leaf: <IconLeaf />,
                        }}
                    />
                    <div>
                        <div className={style.flex}>
                            <Button
                                className={classnames('btn', 'btn-default', style.button)}
                                title="Select All"
                                onClick={checkAll}
                            />
                            <Button
                                className={classnames('btn', 'btn-default', style.button)}
                                title="Deselect All"
                                onClick={uncheckAll}
                            />
                        </div>
                    </div>
                </div>
                <div className={style.modalFooter}>
                    <Button title="Done" className="btn btn-primary" onClick={closeModal} />
                </div>
            </Modal>
            <InfoTooltip text="Select team members for inclusion in this report" />
        </div>
    );
};

TeamMemberSelector.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    // from redux
    setTeamMemberTypes: PropTypes.func,
    respondents: PropTypes.array,
};

const mapStateToProps = state => ({
    respondents: state.app.report.respondents,
});

export default connect(mapStateToProps, {
    setTeamMemberTypes,
})(TeamMemberSelector);
